<template lang="pug">
BasePane(showAdvanced)
  BackgroundAndBorder(
    typeOfComponent="page"
    shadow
    :colorLabel="$t('page')"
    :deviceSelector="false"
  )
  hr.mt-1.mb-4
  Accordion(fix)
    template(#title) {{ $t('sizeAndSpacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    template(v-if="showSize && !isEmbedded && !isFullscreen")
      RangeInput(
        :label="$t('width')"
        v-model.number="width"
        :min="pageMinWidth"
        :max="pageMaxWidth"
        :step="1"
        :placeholder="placeholderWidth"
        :unit="mobilePreview ? '%' : 'px'"
        fixUnit
      )
      RangeInput(
        v-if="!isFitToScreen"
        :label="$t('minHeight')"
        v-model.number="minHeight"
        :min="50"
        :max="650"
        :step="1"
        :placeholder="placeholderMinHeight"
        fixUnit
      )
    template(v-if="isEmbedded || isFullscreen")
      Dropdown#content-size-type(
        v-if="!mobilePreview"
        layout="col"
        :label="$t('width')"
        v-model="contentSize"
        :items="contentSizeOptions"
      )
      template(v-if="contentSize === 'manual' || (isFullscreen && mobilePreview)")
        RangeInput(
          :label="!mobilePreview ? '' : $t('width')"
          v-model.number="manualContentWidth"
          :min="manualContentWidthMin"
          :max="manualContentWidthMax"
          :unit="mobilePreview ? '%' : 'px'"
          fixUnit
        )
        Dropdown#horizontal-alignment(
          layout="col"
          :label="$t('horizontalAlignment')"
          :items="horizontalAlignOptions"
          v-model="horizontalAlign"
        )
      Dropdown#horizontal-alignment(
        layout="col"
        :label="$t('verticalAlignment')"
        :items="verticalAlignOptions"
        v-model="verticalAlign"
      )
      RangeInput(
        v-if="isEmbedded"
        :label="$t('minHeight')"
        v-model.number="embeddedMinHeight"
        :min="50"
        :max="650"
      )
    Margin(v-if="!isInCenter || isEmbedded")
    Padding
  hr.my-3
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Padding from '@/components/Editor/Controls/InputGroup/Padding.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import ColumnSplitSvg from '@/editor/components/svg/ColumnSplitSvg.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import { UilAngleRightB } from '@iconscout/vue-unicons';
  import itemMixin from '@/editor/mixins/item';
  import BasePane from './BasePane.vue';
  import DeviceSelector from '../../components/DeviceSelector.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      Heading,
      RangeInput,
      Margin,
      Padding,
      Hide,
      BackgroundAndBorder,
      ColumnSplitSvg,
      Dropdown,
      UilAngleRightB,
      DeviceSelector,
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
    }),
    computed: {
      ...mapState(['hasWheel', 'page', 'selectedPage', 'mobilePreview', 'globalStyle']),
      ...mapGetters(['isNano', 'isSidebar', 'isEmbedded', 'isFullscreen', 'isTemplateEditorMode']),
      width: {
        get() {
          return Number(this.getValueOf('width'));
        },
        set(value) {
          return this.setValueOf('width', value);
        },
      },
      placeholderWidth() {
        if (this.mobilePreview && !this.getValueOf('mobile.width')) {
          return `${this.getValueOf('desktop.width', 'auto')}`;
        }
        return 'auto';
      },
      pageMinWidth() {
        return this.mobilePreview ? 15 : 200;
      },
      pageMaxWidth() {
        if (this.mobilePreview) return 100;
        if (this.hasWheel) return 1000;
        if (this.isEmbedded) return 1300;
        return 980;
      },
      minHeight: {
        get() {
          return this.getValueOf('desktop.minHeight');
        },
        set(value) {
          this.setValueOf('desktop.minHeight', value);
        },
      },
      placeholderMinHeight() {
        return `${this.getValueOf('desktop.minHeight', 0)}`;
      },
      isFitToScreen() {
        return this.getValueOf('globalStyle.overlay.fitToScreen');
      },
      isInCenter() {
        return this.globalStyle.overlay.position === 5;
      },
      showSize() {
        const canShow = !(this.isNano || (this.hasWheel && !this.isTemplateEditorMode));
        return (canShow && this.mobilePreview) || (canShow && !this.mobilePreview);
      },
      contentSizeOptions() {
        return [
          { text: this.$t('w100'), value: '100w' },
          { text: this.$t('manual'), value: 'manual' },
        ];
      },
      contentSize: {
        get() {
          return this.getValueOf('subElements.content.desktop.size');
        },
        set(value) {
          this.setValueOf('subElements.content.desktop.size', value);
        },
      },
      manualContentWidthMin() {
        return this.mobilePreview ? 15 : 150;
      },
      manualContentWidthMax() {
        return this.mobilePreview ? 100 : 1920;
      },
      manualContentWidth: {
        get() {
          return Number(this.getValueOf('subElements.content.$device.maxWidth'));
        },
        set(value) {
          this.setValueOf('subElements.content.$device.maxWidth', Number(value));
        },
      },
      horizontalAlignOptions() {
        return this.getAligmentOptions();
      },
      horizontalAlign: {
        get() {
          return this.getValueOf('desktop.horizontalAlign');
        },
        set(value) {
          this.setValueOf('desktop.horizontalAlign', value);
        },
      },
      verticalAlignOptions() {
        return this.getAligmentOptions(true);
      },
      verticalAlign: {
        get() {
          return this.getValueOf('desktop.verticalAlign');
        },
        set(value) {
          this.setValueOf('desktop.verticalAlign', value);
        },
      },
      embeddedMinHeight: {
        get() {
          return Number(this.getValueOf('desktop.minHeight'));
        },
        set(value) {
          this.setValueOf('desktop.minHeight', Number(value));
        },
      },
    },
    methods: {
      getAligmentOptions(vertical = false) {
        return [
          { text: this.$t(vertical ? 'top' : 'left'), value: 'flex-start' },
          { text: this.$t('center'), value: 'center' },
          { text: this.$t(vertical ? 'bottom' : 'right'), value: 'flex-end' },
        ];
      },
    },
  };
</script>
